import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const calculateTimeLeft = () => {
    // const difference = +new Date(`2020-09-27`) - +new Date();
    const difference = new Date(2024, 10, 4, 7, 0, 0, 0) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dagen: Math.floor(difference / (1000 * 60 * 60 * 24)),
        uren: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minuten: Math.floor((difference / 1000 / 60) % 60),
        seconden: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [language, setLanguage] = useState("nl");
  // const [btw, setBTW] = useState("");
  // const [klantnummer, setKlantnummer] = useState("");
  // const [errorNL, setErrorNL] = useState("");
  // const [errorFR, setErrorFR] = useState("");
  // const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  // const getCustomerData = () => {
  //   console.log(btw);
  //   console.log("BE" + btw.substring(2, 12));
  //   if (btw.length === 12) {
  //     axios
  //       .get(
  //         `https://live2.sax-professional.be/klantenconversies?ondernemingsnummer=BE${btw.substring(
  //           2,
  //           12
  //         )}`
  //       )
  //       .then((res) => {
  //         console.log(res.data);
  //         if (res.data.length == 1) {
  //           console.log(res.data);

  //           setKlantnummer(res.data[0].klantnummer);
  //           setErrorNL("");
  //           setErrorFR("");
  //         } else if (res.data.length > 1) {
  //           setKlantnummer("");
  //           setErrorNL(
  //             "Wij vonden meerdere klantnummers terug in onze database voor het opgegeven ondernemingsnummer. Gelieve contact op te nemen met uw contactpersoon."
  //           );

  //           setErrorFR(
  //             "Nous avons trouvé plusieurs numéros de clients dans notre base de données pour le numéro d'entreprise. Veuillez contacter votre personne de contact."
  //           );
  //         } else {
  //           setKlantnummer("");
  //           setErrorNL("Wij konden uw klantnummer niet terugvinden.");
  //           setErrorFR("Nous n'avons pas trouvé votre numéro de client.");
  //         }
  //         console.log(res.data);
  //       });
  //   } else {
  //     //BTW nummer heeft niet de correcte lengte
  //     setKlantnummer("");
  //     setErrorNL("Het opgegeven ondernemingsnummer is ongeldig.");
  //     setErrorFR("Le numéro de TVA n'est pas valide.");
  //   }
  // };

  return (
    <div>
      <div className="stglogo">
        <img src="./logo_paepens.png" alt="Logo Paepens NV" />
      </div>
      <div className="countDownHeader">
        <div>
          <h1>
            {language === "nl"
              ? "We zijn momenteel de website van Paepens aan het integreren in de webshop van STG."
              : "Nous sommes actuellement en train d’intégrer le site web de Paepens dans la boutique en ligne de STG."}
          </h1>
          <p>
            {language === "nl"
              ? `Aarzel niet om je contactpersoon te contacteren bij vragen. Bedankt voor je geduld.`
              : `N'hésitez pas à contacter votre personne de contact en cas de questions. Merci pour votre patience.`}
          </p>
        </div>
      </div>
      <div className="countDownHeader">
        <div className="countDownBlock">
          <h1>{timeLeft.dagen}</h1>
          <h6> {language === "nl" ? "Dagen" : "Jours"}</h6>
        </div>
        <div className="countDownBlock">
          <h1>{timeLeft.uren}</h1>
          <h6>{language === "nl" ? "Uren" : "Heures"}</h6>
        </div>
        <div className="countDownBlock">
          <h1>{timeLeft.minuten}</h1>
          <h6>{language === "nl" ? "Minuten" : "Minutes"}</h6>
        </div>
        <div className="countDownBlock">
          <h1>{timeLeft.seconden}</h1>
          <h6> {language === "nl" ? "Seconden" : "Secondes"}</h6>
        </div>
      </div>
      <div className={`countDownHeader black`}>
        {/* <div>
          <h2>
            {language === "nl"
              ? "Heb jij jouw nieuwe klantnummer al?"
              : "Avez-vous déjà votre nouveau numéro de client ?"}
          </h2>
          <p>
            {language === "nl"
              ? `Inloggen op de vernieuwde webshop doe je met een nieuw klantnummer en je huidige gebruikersnaam & wachtwoord. \n Hieronder kan je jouw nieuwe klantnummer opvragen op basis van jouw BTW-nummer.`
              : `Vous pouvez vous connecter à notre boutique en ligne renouvelée, avec un nouveau numéro de client et votre nom d’utilisateur et votre mot de passe actuels. \n  Ci-dessous, vous pouvez demander votre nouveau numéro de client avec votre numéro de TVA.`}
          </p>

          <div className="custnumbform">
            <fieldset>
              <input
                type="text"
                placeholder="BE0000000000"
                onChange={(e) => setBTW(e.target.value)}
              ></input>
              <button onClick={() => getCustomerData("BTW OKE")}>
                {language === "nl" ? "Bevestig" : "Confirmer"}
              </button>
            </fieldset>
            <div className="custResponse">
              {klantnummer.length > 0 ? (
                <p>
                  Uw nieuwe klantnummer is <b>{klantnummer}</b>
                </p>
              ) : errorNL.length > 0 ? (
                language === "nl" ? (
                  <p>{errorNL}</p>
                ) : (
                  <p>{errorFR}</p>
                )
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div> */}
      </div>
      <div className="languagewrapper">
        <div
          className={`languagebutton ${
            language === "nl" ? "activebutton" : ""
          }`}
          onClick={() => setLanguage("nl")}
        >
          NL
        </div>
        <div
          className={`languagebutton ${
            language === "fr" ? "activebutton" : ""
          }`}
          onClick={() => setLanguage("fr")}
        >
          FR
        </div>
      </div>
    </div>
  );
}

export default App;
